import styled, { themeGet, css, device } from "@theme/utils";
export const MainSection = styled.section`
    padding: 75px 0px;
    ${device.xlarge} {
        padding: 100px 0px;
    }
`;

export const Title = styled.h1`
    font-family: "Ruda", sans-serif;
    font-size: 4.5rem;
    margin-bottom: 20px;
    font-weight: 700;
    line-height: 1.2;
`;
export const SubTitle = styled.h4`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    color: blue;
    padding-top: 1rem;
    ${device.xlarge} {
        font-size: 2.5rem;
    }
`;
export const Description = styled.p`
    font-family: "Roboto", sans-serif;
    color: #343a40;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 15px;
    text-align: justify;
    text-justify: inter-word;
`;
